export default () => ({
  ships: [],
  detailsVisible: false,
  filterVisible: true,
  fleetchartVisible: false,
  fleetchartZoomData: null,
  fleetchartViewpoint: "side",
  fleetchartLabels: false,
  fleetchartScreenHeight: "1x",
  fleetchartMode: "panzoom",
  fleetchartScale: 1,
  fleetchartColored: false,
  preview: true,
  money: true,
  starterGuideVisible: true,
  perPage: 30,
  gridView: true,
  extensionReady: false,
});
