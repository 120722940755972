export default () => ({
  detailsVisible: false,
  filterVisible: true,
  fleetchartVisible: false,
  fleetchartZoomData: null,
  fleetchartViewpoint: "side",
  fleetchartLabels: false,
  fleetchartScreenHeight: "1x",
  fleetchartMode: "panzoom",
  fleetchartScale: 1,
  fleetchartColored: false,
  perPage: 30,
  grouped: true,
  money: true,
  preview: true,
  inviteToken: null,
});
