import createPersistedState from "vuex-persistedstate";

export default function getStorePlugins() {
  return [
    createPersistedState({
      key: "FleetYards",
      paths: [
        "locale",
        "storeVersion",
        "app.navSlim",
        "session.authenticated",
        "session.accessConfirmed",
        "cookies.infoVisible",
        "cookies.cookies",
        "hangar.ships",
        "hangar.detailsVisible",
        "hangar.fleetchartVisible",
        "hangar.fleetchartScale",
        "hangar.fleetchartZoomData",
        "hangar.fleetchartViewpoint",
        "hangar.fleetchartLabels",
        "hangar.fleetchartScreenHeight",
        "hangar.fleetchartMode",
        "hangar.fleetchartScale",
        "hangar.fleetchartColored",
        "hangar.preview",
        "hangar.money",
        "hangar.starterGuideVisible",
        "hangar.perPage",
        "hangar.gridView",
        "wishlist.ships",
        "wishlist.detailsVisible",
        "wishlist.perPage",
        "wishlist.gridView",
        "publicHangar.fleetchartVisible",
        "publicHangar.fleetchartScale",
        "publicHangar.fleetchartZoomData",
        "publicHangar.fleetchartViewpoint",
        "publicHangar.fleetchartLabels",
        "publicHangar.fleetchartScreenHeight",
        "publicHangar.fleetchartMode",
        "publicHangar.fleetchartScale",
        "publicHangar.fleetchartColored",
        "models.detailsVisible",
        "models.fleetchartVisible",
        "models.fleetchartScale",
        "models.fleetchartZoomData",
        "models.fleetchartViewpoint",
        "models.fleetchartLabels",
        "models.fleetchartScreenHeight",
        "models.fleetchartMode",
        "models.fleetchartScale",
        "models.fleetchartColored",
        "models.holoviewerVisible",
        "models.perPage",
        "search.history",
        "fleet.detailsVisible",
        "fleet.fleetchartVisible",
        "fleet.fleetchartScale",
        "fleet.fleetchartZoomData",
        "fleet.fleetchartViewpoint",
        "fleet.fleetchartLabels",
        "fleet.fleetchartScreenHeight",
        "fleet.fleetchartMode",
        "fleet.fleetchartScale",
        "fleet.fleetchartColored",
        "fleet.grouped",
        "fleet.money",
        "fleet.preview",
        "fleet.inviteToken",
        "fleet.perPage",
        "publicFleet.fleetchartVisible",
        "publicFleet.fleetchartScale",
        "publicFleet.fleetchartZoomData",
        "publicFleet.fleetchartViewpoint",
        "publicFleet.fleetchartLabels",
        "publicFleet.fleetchartScreenHeight",
        "publicFleet.fleetchartMode",
        "publicFleet.fleetchartScale",
        "publicFleet.fleetchartColored",
        "shoppingCart.items",
      ],
    }),
  ];
}
